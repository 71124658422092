/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'variables';
@import 'mixins';

html,
body {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    // set default colors of ZUi
    --bs-body-bg: var(--zui-color-gs-120);
    --bs-body-color: var(--zui-color-text-default);
    font: var(--zui-typography-body);
}

.bg-dark {
    background-color: var(--zui-color-gs-110) !important;
}

.ellipsis {
    @include ellipsis(1);
}

.absolute-center {
    @include absolute_center();
}

.page-header {
    border-bottom: 1px solid var(--zui-color-gs-120);
    height: var(--arivisHub-content-header-height);
    padding: calc(var(--zui-gu) * 2);

    .page-title {
        font: var(--zui-typography-h3);
        text-transform: var(--zui-typography-h3-text-transform);
    }
}

zui-interactive-icon {
    padding: 8px;
}

zui-avatar {
    --zui-avatar-color-background: var(--zui-color-ac-db-100);
}
