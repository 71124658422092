@use 'sass:math';

@mixin center-background($color: null, $url: null) {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    @if ($color) {
        background-color: $color;
    }
    @if ($url) {
        background-image: url($url);
    }
}

@mixin ellipsis($num_of_lines: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $num_of_lines;
    line-clamp: $num_of_lines;
    -webkit-box-orient: vertical;
}

@mixin center_vertical($height) {
    top: calc(50% - #{math.div($height, 2)}px);
}

@mixin absolute_position($top: 0, $left: 0, $right: null) {
    position: absolute;
    top: $top;
    @if $right {
        right: $right;
    } @else {
        left: $left;
    }
}

@mixin absolute_center() {
    @include absolute_position(50%, 50%);
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@mixin circle($size: 1rem, $color: var(--ca-arivis-cloud-primary-color), $absolute: false, $top: 0, $left: 0) {
    height: $size;
    width: $size;
    background-color: $color;
    border-radius: math.div($size, 2);
    @if $absolute {
        @include absolute_position($top, $left);
    }
}

@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
}