:root {
    --arivisHub-content-header-height: 64px;
    --arivisHub-pagination-height: 64px;
    --arivisHub-lg-card-width: 240px;
    --arivisHub-lg-card-height: 192px;
    --arivisHub-md-card-width: 192px;
    --arivisHub-md-card-height: 192px;
}

$grid-gutter-width: 8px; // --zui-gu
$spacer: 8px; // --zui-gu
$header-height: 64px;
